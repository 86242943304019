import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "react-slideshow-image/dist/styles.css";
import "./Zuppatrainer.css";
import { Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { Fade} from "react-awesome-reveal";
import mouseHover from "../assets/Autopilot/mousehover.gif";
import { useNavigate } from "react-router-dom";


function Zuppatrainer() {
  const fadeImages = ["newdrone4.jpg", "newdrone5.jpg", "newdrone1.jpg"]; // Replace with your image paths
const navigate = useNavigate()
  const fadeProperties = {
    duration: 1000,
    transitionDuration: 1000,
    infinite: true,
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    autoplay: true,
  };
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  return (
    <div>
      <Header />

      <div className="container">
        <div className="about-header text-center">
          <h3>AJEET MINI ( DGCA Complaints Drone )</h3>
        </div>

        <Carousel>
          {fadeImages.map((image, index) => (
            <Carousel.Item>
              <img src={image} alt={`slide-${index}`} />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <br />

      <div
        style={{
          backgroundColor: "#0f143b",
          color: "white",
          width: "85%",
          margin: "auto",
          borderRadius: "30px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center vh-50"></div>
        <div className="tech d-flex flex-wrap justify-content-center mt-5">
          <div className="text-center">
            <p className="text-center display-1 fw-bold text-light Awesome-feature-text">
              AWESOME FEATURES
            </p>
          </div>
        </div>
        <Fade className="container-one" duration={2000} direction="left">
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">Auto Disarm -Calibration</p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
                Angle Disarm
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content ">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
               Auto Disarm -Propeller
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
               RTH on No Signal
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
                RTH on Low - Battery
              </p>
            </Col>
          </Row>


          <p className="small-text text-center text-warning commercial-text fw-bold">
              Easy Of Use & User Control
            </p>
            <Row className="justify-content-center fs-7 feature-content">
            <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
              Proprietary "DISHA" Software
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content ">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold">
              Full Vehicle Manual Control
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
        One-Click Take Off/ Landing/ Hover
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold">
              Set the Altitude of UAV
              </p>
            </Col>
          </Row>


          <Row className="justify-content-center fs-7 feature-content ">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold">
           Dynamic Flight Plan Adjustment
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
     Waypoint Navigation
              </p>
            </Col>
          </Row>
        
        </Fade>
      </div>

      <br />

      <div
        style={{
          backgroundColor: "orange",
          color: "black",
          width: "85%",
          margin: "auto",
          borderRadius: "30px",
        }}
      >
        <div className="tech d-flex flex-wrap justify-content-center mt-5">
          <div className="text-center">
            <p className="text-center display-1 fw-bold text-light Tech-spec-text mt-5">
              Technical Specification
            </p>
          </div>
        </div>

        
		
		
		<div className="container mt-4">
      <div className="table-responsive">
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th colSpan="2" className="text-center" style={{ color:"white"}}>UAS Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Category/Sub-Category</td>
              <td>Rotorcraft</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Class</td>
              <td>Micro</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Maximum all-up-weight (including payload) in kg</td>
              <td>1.172 Kg</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Overall dimensions (l x b x h) in mm</td>
              <td>259 x 259 x 177</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th colSpan="2" className="text-center" style={{ color:"white"}}>Payload Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Details of each Compatible Payload 
(including Manufacturer Name, Payload 
Specifications with Model No., Intended 
Applications)
</td>
              <td>Not Available</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Variable Load/Consumables (Like fluid 
                etc., if any)</td>
              <td>Not Applicable</td>
            </tr>
            
          </tbody>
        </table>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th colSpan="2" className="text-center" style={{ color:"white"}}>Power plant Details
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Engine details (Manufacturer details, 
                Model No.) </td>
              <td>Not Applicable
              </td>
            </tr>
       
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Engine/Motor</td>
              <td>Brushless Motor</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Power Rating</td>
              <td>1100 Watts</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>No. of Engines/ Motors 
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Total fuel capacity (kg)/ Battery capacity 
              (mAh) 
              </td>
              <td>Battery Capacity: 2600mAh</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Propeller details (Diameter, Max RPM)
              </td>
              <td> Diameter- 5 inches , Pitch-5.5 inches, 
              Maximum RPM–15,120</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th colSpan="2" className="text-center" style={{ color:"white"}}>          Equipment Details
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>GNSS</td>
 <td>Available</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Autonomous Flight Termination System 
              or Return Home (RH)</td>
              <td>Available</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Flashing anti-collision strobe lights</td>
              <td>Available</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>RFID and GSM SIM Card </td>
              <td>
                RFID Not available ,
                GSM SIM Card Available
                </td>
            </tr>

            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>SSR transponder (Mode ‘C’ or ‘S’) or 
              ADS-B OUT equipment(if applicable) </td>
              <td>
              Not Available

                </td>
            </tr>

            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Barometric equipment (with capability for 
                remote sub-scale setting) (if applicable) </td>
              <td>
              Not Available

                </td>
            </tr>

            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Geo-fencing Capability 
 </td>
              <td>
              Available
                </td>
            </tr>

            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Detect and Avoid Capability(if 
                applicable) 
 </td>
              <td>
              Not Available 
                </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th colSpan="2" className="text-center" style={{ color:"white"}}> Remote Pilot Station</th>
            </tr>
          </thead>
         
 

          <tbody>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Ground Control Station Model No.</td>
              <td>Zuppa DISHA GCS</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>GCS App. Version</td>
              <td> V 3.3.4</td>
            </tr>
            
          </tbody>
        </table>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th colSpan="2" className="text-center" style={{ color:"white"}}>C2 Link</th>
            </tr>
          </thead>
          
 
 
 
          <tbody>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Equipment details</td>
              <td>FSi6s Transmitter</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Frequency Bands</td>
              <td>2.402-2.48 GHz</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>ETA issued by WPC Wing, DoT</td>
              <td>ETA-SD-20191207154 dated 12th
              December, 2019</td>
            </tr>
          
          </tbody>
        </table>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th colSpan="2" className="text-center" style={{ color:"white"}}>Performance Details</th>
            </tr>
          </thead>
          

          <tbody>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Maximum Endurance (hr/m)</td>
              <td>15min</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Maximum Range (in km)</td>
              <td>0.5 km</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Maximum Speed (in m/s)</td>
              <td>20 m/s</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Maximum Height attainable/ Maximum 
              Ceiling Height (in ft)</td>
              <td>120</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Operating Altitude (in ft)
             </td>
              <td>120</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Operational Envelope</td>
              <td> VLOS day operations only (within the 
operations/performance
limitations specified in the frozen 
manuals)</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Engine limits (Maximum RPM) / Max.Battery Temperature (in deg C)
              </td>
              <td>60 °C</td>
            </tr>
            <tr>
              <td style={{backgroundColor:"white", color:"black"}}>Propeller limits</td>
              <td>15120 RPM</td>
            </tr>
           
          </tbody>
        </table>
      </div>

      
    </div>
      </div>

      <br />
      <br />
      <div className="text-center">
        <video autoPlay loop muted style={{ minWidth: "100%" }}>
          <source
            src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355960/xatlc73gwycu83ryj0kv.mp4"
            type="video/mp4"
          />
        </video>
      </div>
     
           <div className="button-box" onClick={()=> navigate("/DroneShop")}>
                <button className="buy-button">
                  <p className="buy-title text-center">Buy</p>
                  <img src={mouseHover} alt="Handshake" />
                </button>
              </div>

              <br />
              <br />
      <Footer />
    </div>
  );
}

export default Zuppatrainer;
