import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "./Zuppascout.css";
import "react-slideshow-image/dist/styles.css";
import { Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { Fade} from "react-awesome-reveal";
import mouseHover from "../assets/Autopilot/mousehover.gif";
import { useNavigate } from "react-router-dom";



function Zuppascout() {
  const fadeImages = ["A1.jpg"]; // Replace with your image paths
  const navigate = useNavigate()
  const fadeProperties = {
    duration: 1000,
    transitionDuration: 1000,
    infinite: true,
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    autoplay: true,
  };
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  return (
    <div>
      <Header /> 

      <div className="container">
        <div className="about-header text-center">
          <h3>AJEET MINI SCOUT</h3>
        </div>

        <Carousel>
          {fadeImages.map((image, index) => (
            <Carousel.Item>
              <img src={image} alt={`slide-${index}`} />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <br />

      <div
        style={{
          backgroundColor: "#0f143b",
          color: "white",
          width: "85%",
          margin: "auto",
          borderRadius: "30px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center vh-50"></div>
        <div className="tech d-flex flex-wrap justify-content-center mt-5">
          <div className="text-center">
            <p className="text-center display-1 fw-bold text-light Awesome-feature-text">
              AWESOME FEATURES
            </p>
            {/* <p className="small-text text-center text-warning commercial-text fw-bold">
              Commercial Economical Multicopter Drones
            </p> */}
          </div>
        </div>
        <Fade className="container-one" duration={2000} direction="left">
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">Auto Disarm -Calibration</p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
                Angle Disarm
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content ">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
               Auto Disarm -Propeller
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
               RTH on No Signal
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
                RTH on Low - Battery
              </p>
            </Col>
          </Row>


          <p className="small-text text-center text-warning commercial-text fw-bold">
              Easy Of Use & User Control
            </p>
            <Row className="justify-content-center fs-7 feature-content">
            <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
             Android-based GCS Application
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content ">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold">
              Full Vehicle Manual Control
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
        One-Click Take Off/ Landing/ Hover
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold">
              Set the Altitude of UAV
              </p>
            </Col>
          </Row>


          <Row className="justify-content-center fs-7 feature-content ">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold">
           Dynamic Flight Plan Adjustment
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '300px' }}>
              <p className="fw-bold ">
     Waypoint Navigation
              </p>
            </Col>
          </Row>
        
        </Fade>
      </div>

      <br />

      <div
        style={{
          backgroundColor: "orange",
          color: "black",
          width: "85%",
          margin: "auto",
          borderRadius: "30px",
        }}
      >
        <div className="tech d-flex flex-wrap justify-content-center mt-5">
          <div className="text-center">
            <p className="text-center display-1 fw-bold text-light Tech-spec-text mt-5">
              Technical Specification
            </p>
          </div>
        </div>

        <Fade className="container-one" duration={2000} direction="right">
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
            UAV Weight with Battery Maximum 1.44kgs

              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">Endurance(Ideal Wind & MSL Conditions) 25 Minutes</p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content ">
            <Col md={8}>
              <p className="fw-bold">
               Range up to 1500 Meters
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Battery 4200 mAh, 6S1P, Lithium-Ion
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">GNSS Type GPS,GLONASS,QZSS, GAGAN (Up to 36 Satellites)</p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">Autopilot NavGati (Proprietary) - PCB & Firmware</p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">Sensors Ground Facing LIDAR (TerrainFollow), Barometer, Magnetic Compass, Accelerometer</p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">Launch & Recovery Automated Vertical Take-off & Landing</p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">Flight Modes Waypoint Navigation Mode, Manual Control Mode, Hover, Return to Home, Position Hold</p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold"> Packing & Storage Soft Bag that houses all the sub-systems will be provided</p>
            </Col>
          </Row>
       
        </Fade>
      </div>

      <br />
      <br />
      <div className="text-center">
        <video autoPlay loop muted style={{minWidth:"100%"}}>
          <source src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355956/simslzxy4ftwkdouscnp.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="text-center">
        <video autoPlay loop muted style={{minWidth:"100%"}}>
          <source src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355947/yyqnzl5uylsnf5230bdk.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="button-box" onClick={()=> navigate("/DroneShop")}>
                <button className="buy-button">
                  <p className="buy-title text-center">Buy</p>
                  <img src={mouseHover} alt="Handshake" />
                </button>
              </div>

              <br />
              <br />
      <Footer />
    </div>
  );
}

export default Zuppascout;
