

import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "./NewsAndEvents.css";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";




function NewsAndEvents() {

const navigate = useNavigate("")

useEffect(() => {
  const body = document.querySelector('#root'); 
    body.scrollIntoView({
        behavior: 'smooth'
    }, 500)
  
 
  }, []);

// JSON data

const blogs = [
  {
    title: "Our Company in News",
    thumbnail:
      "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1735300662/gs5nlniunvw76cvw2nax.jpg",
    content:
      "Zuppa’s impactful initiatives and innovations have been featured in various media outlets, showcasing our commitment to a safer India. Explore how our work is shaping the nation’s future.",
      link: "/News",
      Fade:"left"
    },
  {
    title: "Awards and Recognition",
    thumbnail:
      "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1735300661/wnn4cilstyj9vkl4lwhu.jpg",
    content:
      "Our dedication to excellence has earned us prestigious awards, reflecting our commitment to innovation and social responsibility. These accolades inspire us to continue driving positive change.",
      link: "/Awards",
        Fade:"right"
    }
];


  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
    }, []);



  return (
    <>
      <Header />
      <br/>
      <h1 className="text-center about-header">ACCOLADES</h1>

      <div className="blog-card-container-first " style={{ marginTop: "50px", marginBottom:"150px"}}>
      {blogs.map((blog, index) => (
        <div className="fond-first" key={index}>

          <Fade direction={`${blog.Fade}` } duration={1800}>
          <div className="news-cards-first">

       


  <div className="news-thumbnails-first set-down">
    <img
      className="news-left-image-first"
      src={blog.thumbnail}
      alt="Thumbnail"
    />
  </div>





            <div className="right-first">

              <h1 className="news-heading-blog-first">{blog.title}</h1>
         
              <div className="separator-first"></div>
              <p className="news-content-first">{blog.content}</p>
            </div>
            <div className="news-footer-first">
         
              <div className="learn-more-container-first">
              <button
                  className="learn-more-first"
                  onClick={() => navigate(blog.link)}
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
          </Fade>
        </div>
      ))}
    </div>
      <Footer />
    </>
  );
}

export default NewsAndEvents;
