
import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Button } from "react-bootstrap";
import { Zoom } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import "./Career.css";
import hiring from "../assets/images/hiring.gif";

function Career() {
  const navigate = useNavigate();

  const reviewMethod = () => {
    navigate("/Review");
  };

  const applyPost = () => {
    navigate("/CareerForm");
  };

  useEffect(() => {
    document.querySelector("#root").scrollIntoView({ behavior: "smooth" }, 500);
  }, []);

 return (
    <>
      <Header />
      <div className="container">
        {/* <h2 className="about-header text-center">Our Job Vacancies</h2> */}

        <div className="text-center">
          <img
            src={hiring}
            alt="Descriptive Alt Text"
            style={{ maxWidth: "250px", height: "auto", width: "auto" }}
          />
        </div>


         <div className="career-container">
          <JobVacancy
            title="DRONE INTEGRATER"
            experience="3 to 5 years"
            description="Job Description :"
            qualifications="BE,EEE, ECE, ME"
            responsibilities={[
              "Good development experience in developing applications using Java, Spring and Databases.",
              "Experience in Android development and debugging robust systems.",
              "Knowledge of Application Servers and Databases.",
              "Good communication skills oral, written and presentation.",
              "Good experience in testing and test case development for Unit, Integration, Systems and Functionality Testing.",
            ]}
            onClickApply={applyPost}
          />
    
          <br/>

          <JobVacancy
            title="SALES COORDINATOR"
            experience="3 to 5 years"
            description="Job Description :"
            qualifications="BBA, MBA, (Any Degree) Sales & Marketing "
            expectation="3 - 5 LPA"
            responsibilities={[

              "Good experience in sales supporting.",
              "Experience in Automobile Industry.",
              "Good communication skills.(Hindi)",

            ]}
            onClickApply={applyPost}
          />
          <br/>

          <JobVacancy
            title="CUSTOMER SUPPORT"
            experience="2 to 5 years"
            description="Job Description :"
            qualifications="BE, B-Tech, (Any Degree)   customer & Support "
            expectation="3 - 4 LPA"
            responsibilities={[

              "Good experience in customer supporting.",
              "Experience in Automobile Industry.",
              "Experience in Production Support.",
              "Good communication skills.(Hindi)",

            ]}
            onClickApply={applyPost}
          />
          <br/>

        </div>
      </div>

      <div className="employee-title">
        <Zoom right duration={1000}>
          <Button
            onClick={reviewMethod}
            className="employee-review"
            variant="info"
          >
            Employee Reviews
          </Button>
        </Zoom>
      </div>

      <Footer />
      <br />
    </>
  );
}

function JobVacancy({
  title,
  experience,
  description,
  qualifications,
  responsibilities,
  onClickApply,
}) {
  return (
    <div className="career-header">
      <div className="career-para">
        <h6>
          <b>{title}</b>
        </h6>
        <b>Experience: {experience}</b>
        <p>{description}</p>
        <ul className="unorder">
          {responsibilities.map((item, index) => (
            <li key={index} >{item}</li>
          ))}
        </ul>
        <p>
          <b>Qualification :</b> {qualifications}
        </p>
        <Button onClick={onClickApply} variant="primary">
          Apply
        </Button>
      </div>
    </div>
  );
}

export default Career;
