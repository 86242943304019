import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaWhatsapp,
  FaFacebookF,
  FaTwitter,
  FaLinkedin,
  FaEnvelope,
  FaShareAlt,
  FaInstagram,
} from "react-icons/fa";

export default function Software() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showShare, setShowShare] = useState(false);
  const [urlToCopy, setUrlToCopy] = useState("");
  const [slug, setSlug] = useState("");

  const productsData = [
    {
      id: 1,
      img: "software-windows.png",
      title: "Zuppa Disha GCS 3.4.7",
      clickPdfDownload: "Zuppa_DISHA_GCS_347_ALPHA.exe",
      
    },
    {
      id: 2,
      img: "software-windows.png",
      title: "Zuppa Disha GCS GEN4_3.4.9",
      clickPdfDownload: "Zuppa_DISHA_GCS_349_ALPHA.exe",
    },
    {
      id: 3,
      img: "software-windows.png",
      title: "Zuppa Disha GCS GEN4_3.5.1",
      clickPdfDownload: "Zuppa_DISHA_GCS_351_G4_SETUP.exe",
    },
    {
      id: 4,
      img: "software-android.png",
      title: "MavFly Gcs App",
      clickPdfDownload: "Zuppa_OpenGCS_4.5.apk",
      subtitle: "English Version",
    },
    {
      id: 5,
      img: "software-android.png",
      title: "MavFly Gcs App",
      clickPdfDownload: "Zuppa_MAVFLY_4.6_HIND.apk",
      subtitle: "Hindi Version",
    },
	  {
      id: 6,
      img: "software-android.png",
      title: "Zuppa Scout AI example Android App",
      clickPdfDownload: "app-release.apk",
    
    },
    {
      id: 7,
      img: "software-windows.png",
      title: "GCS 3.3.2",
      clickPdfDownload: "GCS_V3.3.2_DRONE_ALPHA.zip",
     
    },
    {
      id: 8,
      img: "software-windows.png",
      title: "GCS 3.3.4",
      clickPdfDownload: "GCS_V3.3.4_NG_DRONE_ALPHA.rar",
     
    },
  ];

  const ToggleShare = (slug, title) => {
    setShowShare(!showShare);

    const currentPath = window.location.pathname;
    const productUrl = `${window.location.origin}${currentPath}#${slug}`;
    setUrlToCopy(productUrl);
    setSlug(slug);
  };

  const handleSocialShare = (platform) => {
    const currentPath = window.location.pathname;
    const productUrl = `${window.location.origin}${currentPath}#${slug}`;
    const encodedUrl = encodeURIComponent(productUrl);
    const encodedTitle = encodeURIComponent(slug);

    switch (platform) {
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodedTitle} ${encodedUrl}`,
          "_blank"
        );
        break;
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
          "_blank"
        );
        break;
      case "linkedin":
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
          "_blank"
        );
        break;
      case "email":
        window.location.href = `mailto:?subject=${encodedTitle}&body=${encodedUrl}`;
        break;
      case "instagram":
        window.open("https://www.instagram.com/", "_blank");

        break;
      default:
        console.log("Unsupported platform");
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
    setShowShare(false);
  };

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);


  return (
    <>
      <Header />
      <div className="container mt-8">
        <h1 className="about-header text-center mb-0">Our Products</h1>

        <div className="row">
          <div>
            <section id="home">
              <div className="home_content" style={{ marginTop: "-60px" }}>
                {productsData.map((item) => (
                  <div
                    key={item.id}
                    id={`product-${item.id}`}
                    className="product_card"
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ margin: "0px" }}>
                      <button
                        type="button"
                        className={"btn"}
                        onClick={() => navigate("/Contact")}
                        style={{
                          position: "absolute",
                          top: -15,
                          height: 40,
                          width: 50,
                          backgroundColor: "white",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1724225059/xy5yvmmcluwtcaeo3apt.png"
                          style={{
                            fontSize: "40px",
                            color: "darkgray",
                            width: "50px",
                          }}
                          alt="Contact Icon"
                        />
                      </button>

                      <figure
                        style={{
                          marginTop: "10px",
                          marginBottom: "0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{ width: "300px", margin: "30px" }}
                          src={`${process.env.PUBLIC_URL}/${item.img}`}
                          alt="item-img"
                        />
                      </figure>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "-10px",
                          marginLeft:"190px"
                        }}
                      >
                        <button
                          onClick={() =>
                            ToggleShare(`product-${item.id}`, item.title)
                          }
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "black",
                          }}
                        >
                          <FaShareAlt size={24} />
                        </button>
                      </div>

                      <div style={{ padding: "10px", margin: "0px" }}>
                        <h6
                          className="software_title"
                          style={{ margin: "0px" }}
                        >
                          {item.title}
                        </h6>
                        {item.subtitle && (
                          <p
                            className="software_subtitle"
                            style={{
                              marginTop: "5px",
                              fontSize: "12px",
                              color: "black",
                              fontWeight: "bolder",
                            }}
                          >
                            {item.subtitle}
                          </p>
                        )}
                      </div>
                    </div>

                    <a
                      href={`${process.env.PUBLIC_URL}/${item.clickPdfDownload}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        style={{
                          padding: "10px",
                          background: "darkorange",
                          borderRadius: "10px",
                          margin: "10px auto",
                          width: "100px",
                          fontWeight: "bolder",
                          color: "white",
                        }}
                      >
                        Download
                      </button>
                    </a>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>

      {showShare && (
        <div
          className="share-popup-social"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff1e6",
            padding: "10px",
            borderRadius: "20px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 1000,
            maxWidth: "90%",
            width: "450px",
            height: "350px",
            boxSizing: "border-box",
          }}
        >
          <button
            onClick={() => setShowShare(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              fontSize: "25px",
              fontWeight: "bold",
              color: "#0f143b",
            }}
          >
            &times;
          </button>
          <div
            style={{
              marginBottom: "35px",
              fontSize: "1.2em",
              fontWeight: "bold",
              color: "#0f143b",
            }}
          >
            Share this product:
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <button
              className="share-icon"
              onClick={() => handleSocialShare("whatsapp")}
              style={{
                backgroundColor: "#25D366",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <FaWhatsapp size={24} color="white" />
            </button>
            <button
              className="share-icon"
              onClick={() => handleSocialShare("facebook")}
              style={{
                backgroundColor: "#3b5998",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <FaFacebookF size={24} color="white" />
            </button>
            <button
              className="share-icon"
              onClick={() => handleSocialShare("twitter")}
              style={{
                backgroundColor: "#1da1f2",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <FaTwitter size={24} color="white" />
            </button>
            <button
              className="share-icon"
              onClick={() => handleSocialShare("linkedin")}
              style={{
                backgroundColor: "#0077b5",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <FaLinkedin size={24} color="white" />
            </button>
            <button
              className="share-icon"
              onClick={() => handleSocialShare("email")}
              style={{
                backgroundColor: "#0077b5",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <FaEnvelope size={24} color="white" />
            </button>
            <button
              className="share-icon"
              onClick={() => handleSocialShare("instagram")}
              style={{
                backgroundColor: "#C13584",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <FaInstagram size={24} color="white" />
            </button>
          </div>
          <button
            className="share-icon"
            onClick={handleCopyUrl}
            style={{
              padding: "10px",
              borderRadius: "10px",
              cursor: "pointer",
              backgroundColor: "darkorange",
              marginTop: "25px",
              width: "200px",
              height: "40px",
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Copy URL to Clipboard
          </button>
        </div>
      )}

      <Footer />
    </>
  );
}
