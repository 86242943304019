import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "./About.css";
import { Fade, Zoom } from "react-awesome-reveal";
import drone from "../assets/images/drone.png";
import { MotionAnimate } from "react-motion-animate";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";

function About() {
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  return (
    <>
      <Header />
      <div className="container" id="about">
        <div className="about-header text-center">
          <h3>About Us</h3>
        </div>
        <h4 className="text-center">Zuppa | Innovating Indigenous Defense Tech</h4>

        <div style={{paddingBottom:"56.25%", position:"relative", display:"block", width: "90%", margin:"0px auto"}}>
  <iframe width="100%" height="80%"
    src="https://www.youtube.com/embed/ClSgCxC6HKU" title="How to Calibrate ESC-TUTORIAL 3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen style={{position:"absolute", top:"0", left: "0"}}>
  </iframe>
</div>
   
   
         
        <h4 style={{textAlign:"center"}}>
            SYSTEM OF DISSEMINATED PARALLEL CONTROL COMPUTING IN REAL TIME
          </h4>

        <Fade className="about-container" duration={1800} direction="left">
          <div className="row align-items-center">
            <div className="col-lg-5 mb-lg-3">
              <img
                className="img-fluid"
                src="https://zuppa.io/img/abt-down.png"
                alt=""
              />
            </div>
            <div className="col-lg-7">
              <div className="about-para">
                <p>
                  <span className="span-tag">Z</span>uppa Geo Navigation
                  Technologies Pvt. Ltd. better known as ZUPPA, is a completely
                  India grown drone manufacturing company, founded in the year
                  2008, by father-son duo,{" "}
                  <b>Sai Pattabiram and Venkatesh Sai.</b>
                </p>
                <p>
                  All ZUPPA Motherboards are based on our proprietary Computing
                  Architecture of DISSEMINATED PARALLEL CONTROL COMPUTING IN
                  REALTIME
                </p>
                <p>
                  Design is not just what it looks like and feels like. The
                  design is how it works - Steve Jobs. ZUPPA's End 2 End Control
                  of the technology from Design to Delivery ensures flawless
                  performance of its products.
                </p>
              </div>
            </div>
          </div>
        </Fade>

        <br />
        <Fade className="about-container-one" duration={2000} direction="right">
          <div className=" d-flex justify-content-between row align-items-center">
            <div className="col-lg-5 order-lg-5 mb-3 mb-lg-3">
              <img
                className="img-fluid "
                src="https://zuppa.io/img/why-zuppa.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="about-para-one">
                <h5>
                  WHY ZUPPA <span style={{ color: "red" }}>?</span>
                </h5>
                <p>
                  <span className="span-tag">Z</span>UPPA's End 2 End Control of
                  the technology from Design to Delivery ensures flawless
                  performance of its products.
                </p>
                <p>
                  Funded under GOI innovation Program of Department of Science &
                  Technology TIFAC SIDBI SRIJAN Innovation
                </p>
                <p>
                  Patent Applied for Unique{" "}
                  <b>MADE IN INDIA Artificial Intelligence AI</b> Technology.
                </p>
                <p>
                  Only Drone Company to manufacture Automobile Industry Standard
                  AIS 140 Compliant Ministry Of Road Transport & Highways
                  (MORTH) approved Vehicle Tracking & Locating Device
                </p>
              </div>
            </div>
          </div>
        </Fade>
        <br />
        <Fade className="about-container-two" duration={3000} direction="left">
          <div className=" d-flex justify-content-between row align-items-center">
            <div className="col-lg-3 mb-1 mb-lg-6">
              <img
                className="img-fluid"
                id="two-image"
                src="https://www.zuppa.io/product-img/product.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <div className="about-para-two">
                <p>
                  <span className="span-tag">Z</span>UPPA's End 2 End Control of
                  the technology from Design to Delivery ensures flawless
                  performance of its products .
                </p>
                <p>TANSTIA FNF Germany's Innovation voucher program</p>
                <p>
                  Proprietary Unique MADE IN INDIA Artificial Intelligence (AI)
                  Technology.
                </p>
                <p>
                  Only Drone Company with AS9100D Aerospace Process Standard
                </p>
              </div>
            </div>
          </div>
        </Fade>

        <MotionAnimate
          variant={{
            hidden: { opacity: 0.2, rotate: -180 },
            show: {
              opacity: 0.8,
              rotate: 0,
              transition: {
                repeat: Infinity,
                duration: 4,
                repeatDelay: 1,
                type: "spring",
              },
            },
          }}
        >
          <Zoom right duration={8000}>
            <div className="row justify-content-center ">
              <div className="col-lg-6 ">
                <img
                  className="img-fluid-drone custom-img"
                  src={drone}
                  alt=""
                />
              </div>
            </div>
          </Zoom>
        </MotionAnimate>
      </div>
      <Footer />
    </>
  );
}

export default About;
