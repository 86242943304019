
import * as React from "react";
import { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import cartContext from "../context/cartContext";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import headerlogo from "../assets/images/headerzuppa.png";
import PersonIcon from '@mui/icons-material/Person';
import Modal from "@mui/material/Modal"; // Import Modal component
// import Profile from "./Profile"; // Import Profile component
import Avatar from '@mui/material/Avatar';
// import ReactGA from "react-ga4";

const pages = [
  { name: "Home", nav: "/" },
  { name: "About Us", nav: "/About" },
  { name: "Technology", nav: "/Technology" },
  { name: "Accolades", nav: "/NewsAndEvents" },
  { name: "Shop", nav: "/ShopNow" },
  { name: "Contact", nav: "/Contact" },
  { name: "DroneLabs", nav: "/dronelabs" },
  { name: "Support" }
];

const settings = [
  { name: "My Account"},
  { name: "My Orders" },
];

 const supportOptions = [
    { name: "FAQs" ,
       nav: "/faq-page"  },
    {
      name: "Explainer Videos",
      nav: "https://www.youtube.com/playlist?list=PLvg5kmiEqEtUHtu7DR1UB6oDK4d9uMBAC",
    },
    { name: "Warranty Terms", 
      nav: "/warranty-term" },
  ];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openProfileModal, setOpenProfileModal] = useState(false); // State for controlling the modal
  const [anchorElSupport, setAnchorElSupport] = useState(null); // State for Support dropdown
  const [supportOpen, setSupportOpen] = useState(false); // State for toggling the support accordion

  const { cartItems, toggleCart } = React.useContext(cartContext);
  const cartQuantity = cartItems.length;
  const navigate = useNavigate();
  const authToken = localStorage.getItem("zuppa");
  const username = localStorage.getItem("username");


  // const handleHeaderNavigation = (nav) => {
  //   ReactGA.event('event', 'select_content', {
  //     content_type: 'navigation',
  //     item_id: nav, // This can be the URL or descriptive identifier
  //   });

  //   if (nav) {
  //     window.location.href = nav; // Navigate to the specified route
  //   }
  // };


  const handleHeaderNavigation = (nav) => {
    if (nav) {
      window.location.href = nav; // Navigate to the specified route
    }
  };

  const toggleSupportAccordion = () => {
    setSupportOpen((prev) => !prev); // Toggle the support accordion state
  };
  const handleSupportMenuOpen = (event) => {
    setAnchorElSupport(event.currentTarget);
  };

  const handleSupportMenuClose = () => {
    setAnchorElSupport(null);
  };



  const logOut = () => {
    localStorage.removeItem("zuppa");
    localStorage.removeItem("username");
    localStorage.removeItem("id");
    window.location.href = "/Login";
  };

  const logIn = () => {
    navigate("/Login");
  };

  const handleUserMenuOpen = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorElUser(null);
  };

  const handleOpenProfileModal = () => {
    setOpenProfileModal(true); // Open the profile modal
    handleUserMenuClose(); // Close the user menu
  };

  const handleCloseProfileModal = () => {
    setOpenProfileModal(false); // Close the profile modal
  };


  const handleClickLogin= () =>{
    // ReactGA.event({
    //   category: 'Login Button',
    //   action: 'Login via header',
    //   label: 'Login',
    // });

  }
  const handleClickLogout=() =>{
    // ReactGA.event({
    //   category: 'Logout Button',
    //   action: 'Logout via header',
    //   label: 'Logout',
    // });
  }
  
   const handleNavigation = (nav) => {
    if (nav.startsWith("http")) {
      // Open external link in a new tab
      window.open(nav, "_blank");
    } else if (nav) {
      // Navigate to internal route
      navigate(nav);
    }
  };
  

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
      <Container maxWidth="lg">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <IconButton edge="start" color="inherit" aria-label="menu" >
            <img src={headerlogo} alt="Zuppa" style={{ width: "50px" }} />
          </IconButton>

          {/* Mobile Menu */}
           {/* Mobile Menu */}
           <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => setAnchorElNav(event.currentTarget)}
            >
              <MenuIcon color="warning" />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
            >
              {pages.map((page, index) =>
                page.name === "Support" ? (
                  <MenuItem
                    key={index}
                    onClick={(event) => {
                      toggleSupportAccordion(); // Toggle accordion state
                    }}
                  >
                    <Typography textAlign="center">
                      {page.name} {supportOpen ? "-" : "+"} {/* Show plus or minus */}
                    </Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setAnchorElNav(null);
                      handleHeaderNavigation(page.nav);
                    }}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                )
              )}

              {/* Support Accordion Content */}
              {supportOpen && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "8px",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  {supportOptions.map((option, index) => (
                    <Button
                      key={index}
                      onClick={() => {
                        handleHeaderNavigation(option.nav);
                        setAnchorElNav(null); // Close the menu
                      }}
                      sx={{ justifyContent: "flex-start", color: "black" }}
                    >
                      {option.name}
                    </Button>
                  ))}
                </Box>
              )}
            </Menu>
          </Box>


          {/* Desktop Menu */}
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: 'center', gap: "30px" }}>
          {pages.map((page, index) =>
              page.name === "Support" ? (
                <Button
                  key={index}
                  onClick={handleSupportMenuOpen}
                  sx={{ color: 'white', fontWeight: 'bold' }}
                >
                  {page.name}
                </Button>
              ) : (
                <Button
                  key={index}
                  onClick={() => handleHeaderNavigation(page.nav)}
                  sx={{ color: 'white', fontWeight: 'bold' }}
                >
                  {page.name}
                </Button>
              )
            )}
            {/* <IconButton 
            // onClick={() => toggleCart(true)} 
            onClick={() => navigate("/Contact")}
            >
              {cartQuantity > 0 ? (
                <Badge badgeContent={cartQuantity} color="error">
                  <ShoppingCartIcon fontSize="large" style={{ color: "orange" }} />
                </Badge>
              ) : (
                <ShoppingCartIcon fontSize="large" style={{ color: "orange" }} />
              )}
            </IconButton> */}
          </Box>

{/* Support Dropdown */}
          <Menu
  id="support-menu"
  anchorEl={anchorElSupport}
  open={Boolean(anchorElSupport)}
  onClose={handleSupportMenuClose}
  anchorOrigin={{
    vertical: 'bottom', 
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'top', 
    horizontal: 'center',
  }}
  PaperProps={{
    style: {
      maxWidth: '200px', // Adjust width if needed
      marginTop: '8px', // Small gap from the button
    },
  }}
>
  {supportOptions.map((option, index) => (
    <MenuItem
      key={index}
      onClick={() => {
        handleSupportMenuClose();
        handleHeaderNavigation(option.nav);
		handleNavigation(option.nav);
      }}
    >
      {option.name}
    </MenuItem>
  ))}
</Menu>

          {/* User Icon */}
         {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
            {authToken ? (
              <>
                <Typography variant="body1" sx={{ color: "white" }}>{username}</Typography>
                <IconButton onClick={handleUserMenuOpen} color="warning">
                <Avatar alt="Profile" src="" fontSize="large" />
                </IconButton>
                <Menu
                  id="user-menu"
                  anchorEl={anchorElUser}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorElUser)}
                  onClose={handleUserMenuClose}
                  style={{ marginTop: "50px" }}
                >
                  {settings.map((setting, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        // if (setting.nav === "/Profile") {
                        //   handleOpenProfileModal(); 
                        // } else {
                        //   handleUserMenuClose();
                          navigate(setting.nav);
                        // }
                      }}
                    >
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                  ))}
                  <MenuItem onClick={()=>{ logOut(); handleClickLogout();}}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => { logIn(); handleClickLogin(); }}
              
              >
                Login
              </Button>
            )}
          </Box>
		  */}
        </Toolbar>
      </Container>

    </AppBar>
  );
}

export default ResponsiveAppBar;
