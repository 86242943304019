import React, { useEffect } from "react";
import ProductsCard from "../ProductsCard";
import Header from "../header";
import Footer from "../footer";
import { useLocation } from "react-router-dom";
import "./AutoPilot.css";

const AutoPilot = () => {
  const productsData = [
    {
      id: 1,
      slug: "Elevate_Lite_(_1_GPS_)",
      img: "/images/elevate-agri.png",
      rating: "★★★★",
      title: "Elevate Lite ( 1 GPS )",
      subtitle: "(No GPS, No Power Module, No Software & No Memory Card)",
      description:
        "Only “ Made In India “ Autopilot for Agri Drones delivering superior Performance and features than any imported flight controller lower prices",
      keyfeatures:
        " \n Altitude-stablization mode \n, GPS-speed in angle mode ,\n AB operation mode, \n Automatic return mode ",
      strickPrice: 51000,
        price: 45000,
      gst: "+ GST 18 %",
      desimg: "/images/TWO OPTIONS.png",
      popupDescription:
        "Only “ Made In India “ Autopilot for Agri Drones delivering superior Performance and features than any imported flight controller lower prices ( less than 10kg Drone ).",
     popupspecification:
        "APP Configurable Spray Control  protocol \n 2 Powerful 32 Bit STM 32 Processors working in parallel using ZUPPA's Patented technology   \n 2 barometers  and IMU’s for altitude accuracy , enhanced performance. and redundancy \n  Plug and Fly  Pre Tuned PID Gains \n Fully Made in India ZUPPA’s proprietary Firmware based on our DPCC technology ensures Autopilot is the latest version always ",
      popupkeyfeatures:
        " \u25CF Fully  Designed , Developed , manufactured  in India based on our  Patented Indian DPCC technology \n \u25CF Personalised Tech Support ensuring  immediate sales of drones \n \u25CF Can be integrated to any Mavlink based GCS like ZUPPA MavFly , AeroGCS , QGCS etc \n \u25CF Autopilot proven across India from  sea level to over 15,000 ft altitude",
      quantity: 1,
      clickPdfDownload: "/images/Autopilots.pdf",
      video:"https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355951/istn0nqu77jeojodko17.mp4"
    },
    {
      id: 2,
      slug: "Elevate_Pro_(_2_GPS_)",
      img: "/images/elevate.png",
      rating: "★★★★★",
      title: "Elevate Pro ( 2 GPS )",
      subtitle: "(NO Memory Card & NO Software)",
      description:
        "Only “ Made In India “ High precision Autopilot for applications like Survey, Mapping , Logistics Delivering  superior  Performance and features than any imported flight controller lower prices ",
      keyfeatures:
        " \n Altitude-stablization mode \n, GPS-speed in angle mode ,\n AB operation mode, \n Automatic return mode ",
       strickPrice: 81500,
        price: 75000,
      gst: "+ GST 18 %",
      desimg: "/images/TWO OPTIONS.png",
        popupDescription:
        "Only “ Made In India “ High precision Autopilot for applications like Survey, Mapping , Logistics … Delivering  superior  Performance and features than any imported flight controller lower prices ( less than 25kg Drone )",
      popupkeyfeatures:
        " \u25CF Fully  Designed , Developed , manufactured  in India based on our  Patented Indian DPCC technology\n \u25CFPersonalised Tech Support ensuring  immediate sales of drones  \n \u25CF Can be integrated to any Mavlink based GCS like ZUPPA MavFly , AeroGCS , QGCS etc \n  \u25CF Autopilot proven across India from  sea level to over 15,000 ft altitude      ",
      popupspecification:
        "Dual GPS for Higher level Locational Accuracy.\nOptional : Base Station integration for centimetre level accuracy  \n 2 Powerful 32 Bit STM 32 Processors working in parallel using ZUPPA's Patented technology       .\n 2 barometers  and IMU’s for altitude accuracy , enhanced performance. and redundancy       \n Plug and Fly  Pre Tuned PID Gains       \n  Fully Made in India ZUPPA’s proprietary Firmware based on our DPCC technology ensures Autopilot is the latest version always       ",
      quantity: 1,
      clickPdfDownload: "/images/Autopilots.pdf",
      video:"https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355951/istn0nqu77jeojodko17.mp4"
    },
    {
      id: 3,
      slug: "Elevate_Secure_Defence_(_2_GPS_+_\"Suraksha_Firewall\"_)",
      img: "/images/elevate.png",
      rating: "★★★★★",
      title:  "Elevate Secure Defence ( 2 GPS + \"Suraksha Firewall\" )",
      subtitle: "(NO Memory Card & NO Software)",
      description:
        "Only “ Made In India “ High precision Autopilot for applications like Survey, Mapping , Logistics Delivering  superior  Performance and features than any imported flight controller lower prices ",
      keyfeatures:
        " \n Altitude-stablization mode \n, GPS-speed in angle mode ,\n AB operation mode, \n Automatic return mode ",
      strickPrice: 119000,
        price: 99900,
      gst: "+ GST 18 %",
      desimg: "/images/TWO OPTIONS.png",
       popupDescription:
        "Only “ Made In India “ High precision Autopilot for applications like Survey, Mapping , Logistics … Delivering  superior  Performance and features than any imported flight controller lower prices ( less than 50kg Drone )",
      popupkeyfeatures:
        " \u25CF Fully  Designed , Developed , manufactured  in India based on our  Patented Indian DPCC technology\n \u25CFPersonalised Tech Support ensuring  immediate sales of drones  \n \u25CF Can be integrated to any Mavlink based GCS like ZUPPA MavFly , AeroGCS , QGCS etc \n  \u25CF Autopilot proven across India from  sea level to over 15,000 ft altitude      ",
      popupspecification:
        "Dual GPS for Higher level Locational Accuracy.\nOptional : Base Station integration for centimetre level accuracy  \n 2 Powerful 32 Bit STM 32 Processors working in parallel using ZUPPA's Patented technology       .\n 2 barometers  and IMU’s for altitude accuracy , enhanced performance. and redundancy       \n Plug and Fly  Pre Tuned PID Gains       \n  Fully Made in India ZUPPA’s proprietary Firmware based on our DPCC technology ensures Autopilot is the latest version always       ",
      quantity: 1,
      clickPdfDownload: "/images/Autopilots.pdf",
      video:"https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355951/istn0nqu77jeojodko17.mp4"
    },
  ];

  const location = useLocation();

  useEffect(() => {
    // Extract slug from query parameters
    const queryParams = new URLSearchParams(location.search);
    const slug = queryParams.get("slug");

    if (slug) {
      // Find the element with the matching slug and scroll into view
      const element = document.getElementById(slug);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.search]);


  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  
  return (
    <>
      <Header />
      <div className="container mt-8 autopilot-page">
        <h2 className="about-header text-center mb-0">NAVGATI<span style={{marginRight:"20px"}}></span>AUTOPILOTS</h2>
        <div className="row">
          <section id="home">
            <div className="home_content" style={{ marginTop: "-60px" }}>
              {productsData.map((item) => (
                <ProductsCard key={item.id}  data-slug={item.slug} {...item} />
              ))}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AutoPilot;
