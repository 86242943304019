import React, { useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./FaqPage.css";
import { Fade } from "react-awesome-reveal";

// Updated questions for the FAQ
const faqData = [
  {
    question: "What types of drones are you manufacturing?",
    answer:
      "We specialize in developing a range of advanced drones, including surveillance drones, micro drones for infantry, and commercial drones designed for various industrial applications.",
  },
  {
    question: "Are your drones approved by regulatory authorities?",
    answer:
      "Yes, our DGCA-approved drones meet all necessary regulatory standards. We also comply with AS9100D certifications for aerospace quality management, and ISO 9001:2015 ensuring high-quality and reliable performance.",
  },
  {
    question: "Can your drones be integrated with third-party software or hardware?",
    answer:
      "Absolutely. Our drones and autopilot systems are designed for seamless integration with third-party hardware and software solutions, enabling flexible and scalable deployments.",
  },
  {
    question: "What industries can benefit?",
    answer:
      "Our drone technology is used across multiple sectors, including defense, agriculture, infrastructure, logistics, and emergency response. We also offer custom drone solutions to meet specific business requirements.",
  },
  {
    question: "What are the EDGE and NavGati SOM, and how can they benefit my project?",
    answer:
      "NavGati - our proprietary System On Modules (SOMs) that enable advanced navigation, connectivity, and automation capabilities. These SOMs are suitable for a range of applications, including connected mobility solutions, and next-generation drone systems.",
  },

  {
    question: "Do you provide training or educational programs for drone technology?",
    answer:
      "Yes, we offer specialized drone training and educational programs to help organizations gain in-depth knowledge about drone operations, drone maintenance, and regulatory compliance.",
  },
  {
    question: "Do you offer after-sales support and maintenance services?",
    answer:
      "Yes, we provide comprehensive after-sales support including drone maintenance, software updates, and technical assistance to ensure optimal performance of our drone solutions. (Charges applicable).",
  },
  {
    question: "How do your drones contribute to India's Atmanirbhar Bharat initiative?",
    answer:
      "We are proud to be part of India’s Atmanirbhar Bharat initiative by developing indigenous drone technologies that reduce dependency on foreign imports. Our Made-in-India drones empower sectors like defense, agriculture, and infrastructure, supporting India’s vision of self-reliance in advanced technology.",
  },
  {
    question: "I don’t want to buy your entire drone, I only need the autopilot. Is it available?",
    answer:
      "Yes, we offer standalone autopilot systems for customers who only need navigation and flight control solutions. Our NavGati autopilot module is available for purchase separately, allowing you to integrate them into your existing drone platforms or custom builds.",
  },
  {
    question: "How do I start purchasing a drone from your company?",
    answer:
      "You can visit our official website at https://shop.zuppa.io/ to explore our products and place your orders. You can contact our sales team via our contact form or call us directly. We’ll guide you through the drone product selection, customization options, and purchasing process based on your specific needs.",
  },







];

const FaqPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      <Header />
      <h1 style={{ marginTop: "80px", textAlign: "center" }} className="frequentlyheader">
        Frequently Asked Questions
      </h1>



  
     <div className="faqPage-container">
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`faqPage-item ${activeIndex === index ? "active" : ""}`}
          >
            <div
              className="faqPage-question"
              onClick={() => toggleFAQ(index)}
            >
              <>{item.question}</>
              <span className="faqPage-icon">{activeIndex === index ? "-" : "+"}</span>
            </div>
            {activeIndex === index && <div className="faqPage-answer">
              <p>{item.answer}</p>
            </div>}
          </div>
        ))}
      </div>
    


      <Footer />
    </div>
  );
};

export default FaqPage;
