import React, { useContext, useState } from "react";
import cartContext from "../context/cartContext";
import {
  FaTimes,
  FaFilePdf,
  FaShoppingCart,
  FaShareAlt,
  FaFacebookF,
  FaTwitter,
  FaWhatsapp,
  FaEnvelope,
  FaLinkedin,
  FaInstagram
} from "react-icons/fa";
import { BsCart4 } from "react-icons/bs";
import { MdContactPhone } from "react-icons/md";
import { MdAddShoppingCart } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { backdropClasses } from "@mui/material";

const ProductsCard = (props) => {
  const {
    img,
    rating,
    title,
    subtitle,
    smTitle,
    price,
    gst,
    desimg,
    heading,
    content,
    description,
    strickPrice,
    popupDescription,
    popupkeyfeatures,
    popupspecification,
    clickPdfDownload,
    video,
    slug
  } = props;

  const { cartItems, toggleCart } = React.useContext(cartContext);
  const [showShare, setShowShare] = useState(false);
  const { addItem, isCartOpen } = useContext(cartContext);
  const [urlToCopy, setUrlToCopy] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handleAddToCart = () => {
    const item = { ...props };
    addItem(item);
    setIsAdded(true);
    setTimeout(() => {
      setIsAdded(false);
    }, 3000);
  };

  const TogglePopup = () => {
    setShowPopup(!showPopup); // Toggle popup visibility
  };


  const ToggleShare = () => {
    setShowShare(!showShare);
    const currentPath = window.location.pathname;
    const productUrl = `${window.location.origin}${currentPath}#${slug}`;
    setUrlToCopy(productUrl);
  };

  const handleSocialShare = (platform, slug) => {
    const currentPath = window.location.pathname;
    const productUrl = `${window.location.origin}${currentPath}#${slug}`;
    const encodedUrl = encodeURIComponent(productUrl);
    const encodedTitle = encodeURIComponent(title);

    switch (platform) {
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodedTitle} ${encodedUrl}`,
          "_blank"
        );
        break;
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
          "_blank"
        );
        break;
      case "linkedin":
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
          "_blank"
        );
        break;
      case "email":
        window.location.href = `mailto:?subject=${encodedTitle}&body=${encodedUrl}`;
        break;
        case "instagram":
          window.location.href = `instagram://share?url=${encodedUrl}`;
          break;
      default:
        console.log("Unsupported platform");
    }
  };
  const handleCopyUrl = () => {
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
      setShowShare(false);
  };

  
  return (
    <>
 <div
        className="product_card"
        id={slug}
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ margin: "0px" }}>
          {/* <button
            type="button"
            className={`btn ${isAdded ? "added" : ""}`}
            onClick={handleContactProduct}
            style={{
              position: "absolute",
              top: -15,
              right:0,
              height: 40,
              width: 50, // Adjust width as per your requirement
              backgroundColor: "white",

              color: "black",
              fontWeight: "bold",
              fontSize: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             
            }}
          >
            <img
              src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1724225059/xy5yvmmcluwtcaeo3apt.png"
              style={{ fontSize: "40px", color: "darkgray", width: "50px" }}
            />
          </button> */}
          {/* <button
            type="button"
            className={`btn ${isAdded ? "added" : ""}`}
            // onClick={handleAddToCart}
            onClick={() => navigate("/Contact")}
            style={{
              position: "absolute",
              top: -15,
              right: 0,
              height: 40,
              width: 50, // Adjust width as per your requirement
              backgroundColor: "white",

              color: "black",
              fontWeight: "bold",
              fontSize: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
    
            }}
          >
            {isAdded ? (
              <h6 style={{ color: "darkblue" }}>Added</h6>
            ) : (
              <MdAddShoppingCart
                style={{ fontSize: "40px", color: "darkorange" }}
              />
            )}
          </button> */}
          {/* 
        <button
            type="button"
            className="share-btn"
            onClick={ToggleShare}
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              height: 40,
              width: 40,
              backgroundColor: "white",
              color: "orange",
              fontWeight: "bold",
              fontSize: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            }}
          >
            <FaShareAlt />
          </button> */}

          <figure
            style={{
              marginTop: "-15px",
              marginBottom: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
            
              style={{ width: "100%", height: "250px", maxWidth: "450px" }}
              src={img}
              alt="item-img"
            />
          </figure>

          {/* <button
            type="button"
            className={`btn ${isAdded ? "added" : ""}`}
            onClick={handleContactProduct}
            style={{
              position: "absolute",
              top: 220,
              left:0,
              height: 40,
              width: 50, // Adjust width as per your requirement
              backgroundColor: "white",

              color: "black",
              fontWeight: "bold",
              fontSize: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             
            }}
          >
            <img
              src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1724225059/xy5yvmmcluwtcaeo3apt.png"
              style={{ fontSize: "40px", color: "darkgray", width: "50px" }}
            />
          </button> */}
          <button
            type="button"
            className="share-btn-link"
            onClick={ToggleShare}
            style={{
              margin: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              position: "relative",
              left: "92%",
              top:"5px"
            }}
          >
            <FaShareAlt color="darkorange" size={"20px"}/>
          </button>

          {/* <strong className="rating" style={{ margin:"0px"}}>{rating}</strong> */}
          <div style={{ padding: "10px", marginTop: "-10px" }}>
            <h6
              className="title"
              style={{ fontWeight: "bolder", margin: "0px" }}
            >
              {title}
            </h6>
            <b>{smTitle}</b>

            {/* <h3
              className="price"
              style={{ fontWeight: "initial", fontSize: 20, marginTop: "10px" }}
            >
              <span
                style={{
                  fontWeight: "initial",
                  fontSize: 15,
                  marginRight: "10px",
                  textDecoration: "line-through",
                }}
              >
                ₹ {strickPrice.toLocaleString('en-IN')}
              </span>{" "}
              ₹ {price.toLocaleString('en-IN')}{" "}
              <span
                style={{
                  fontWeight: "bolder",
                  fontSize: 15,
                  marginLeft: "10px",
                  color: "red",
                }}
              >
                {gst}
              </span>
            </h3> */}

            <h3
              className="description"
              style={{
                fontSize: "11px",
                marginLeft: "0px",
                marginBottom: "0px",
              }}
            >

            <p style={{ fontSize: "15px", margin: "0px", maxWidth: "0px" }}>
                {" "}
                Description:
              </p>
              <p
                style={{
                  marginTop: "10px",
                  marginBottom: "0px",
                  textAlign: "justify",
                }}
              >

                {description}{" "}
                <span
                  // onClick={()=>{TogglePopup(); handleKnowMore();
                  onClick={()=>{TogglePopup();
                   
                
                  }}
                  style={{ color: "blue", cursor: "pointer", fontSize: "17px" }}
                >
                  know more..{" "}
                </span>{" "}
              </p>
            </h3>
          </div>
        </div>

       

        <button
          style={{
            padding: "10px",
            background: "darkorange",
            borderRadius: "10px",
            margin: "10px auto",
            width: "100px",
            fontWeight: "bolder",
            color: "white",
          }}
          // onClick={() => [handleAddToCart(), toggleCart(true)]}
          onClick={() => navigate("/Contact")}
        >

          Buy
        </button>

        
      </div>
      {showShare && (
        <div
          className="share-popup-social"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff1e6",
            padding: "10px",
            borderRadius: "20px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 1000,
            maxWidth: "90%",
            width: "450px",
            height: "350px",
            boxSizing: "border-box",
          }}
        >
          <button
            onClick={() => setShowShare(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              fontSize: "25px",
              fontWeight: "bold",
              color: "#0f143b",
            }}
          >
            &times;
          </button>
          <div
            style={{
              marginBottom: "35px",
              fontSize: "1.2em",
              fontWeight: "bold",
              color: "#0f143b",
            }}
          >
            Share this product:
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <button
              className="share-icon"
              onClick={() => handleSocialShare("whatsapp")}
              style={{
                backgroundColor: "#25D366",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <FaWhatsapp size={24} color="white" />
            </button>
            <button
              className="share-icon"
              onClick={() => handleSocialShare("facebook")}
              style={{
                backgroundColor: "#3b5998",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <FaFacebookF size={24} color="white" />
            </button>
            <button
              className="share-icon"
              onClick={() => handleSocialShare("twitter")}
              style={{
                backgroundColor: "#1da1f2",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <FaTwitter size={24} color="white" />
            </button>
            <button
              className="share-icon"
              onClick={() => handleSocialShare("linkedin")}
              style={{
                backgroundColor: "#0077b5",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <FaLinkedin size={24} color="white" />
            </button>
            <button
              className="share-icon"
              onClick={() => handleSocialShare("email")}
              style={{
                backgroundColor: "#0077b5",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <FaEnvelope size={24} color="white" />

              </button>

              <button
  className="share-icon"
  onClick={() => handleSocialShare("instagram")}
  style={{
    backgroundColor: "#C13584",
    padding: "10px",
    borderRadius: "10px",
    cursor: "pointer",
  }}
>
  <FaInstagram size={24} color="white" />
</button>
          </div>
         
               <button
            className="share-icon"
            onClick={handleCopyUrl}
            style={{
              padding: "10px",
              borderRadius: "10px",
              cursor: "pointer",
              backgroundColor: "darkorange",
              marginTop: "25px",
              width: "200px",
              height: "40px",
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Copy URL to Clipboard
          </button>
          </div>
     
      )}

      {!isCartOpen && showPopup && (
        <>
          <div className="custom-popup">
            <div className="popup-content">
              <div className="content-wrapper">
                {/* Left Content */}
                <div className="left-content">
                  <div className="heading-container">
                    <h2 style={{  color: "#e07811"}}>{title}</h2>
                    {/* Common heading */}
                  </div>
                  <hr className="horizontal-line" />

                  {/* Image */}
                  <figure>
                    <img
                      src={desimg}
                      alt="item-img"
                      style={{ maxWidth: "100%" }}
                    />
                  </figure>
                  {/* Add to Cart button */}
                  <div className="addcart">
                    <button
                      type="button"
                      className={`btn ${isAdded}`}
                      onClick={handleAddToCart}
                      style={{
                        fontWeight: "bold",
                        width: 150,
                        height: 50,
                        backgroundColor: "#e07811",
                      }}
                    >
                      {isAdded ? "Added" : "Add to Cart"}
                    </button>
                  </div>
                </div>
                {/* Right Content */}
                <div className="right-content">
                  {/* Description */}
                  <div className="description" style={{ wordWrap:"break-word" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: 25,
                        color: "#e07811",
                      }}
                    >
                      Description:
                    </p>

                    <div className="popdescription" style={{ color: "black", wordWrap:"break-word" }}>
                      {popupDescription.split("\n").map((line, index) => (
                        <div>
                          <p key={index}>{line}</p>
                        </div>
                      ))}
                      <h4
                        className="responsive-heading text-center"
                        style={{ color: "GREEN" }}
                      >
                        {heading}
                      </h4>
                      <p
                        className="responsive-content text-center"
                        style={{ color: "red", fontWeight: "bolder" }}
                      >
                        {content}
                      </p>
                    </div>
                  </div>
                  {/* Specification */}
                  <div className="specification">
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: 25,
                        color: "#e07811",
                      }}
                    >
                      Specification
                    </p>
                    <div
                      className="specification-content"
                      style={{ color: "black" }}
                    >
                      {popupspecification.split("\n").map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                    </div>
                  </div>
                  {/* Key Features */}
                  <div className="keyfeaturespop">
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: 25,
                        color: "#e07811",
                      }}
                    >
                      Key Features
                    </p>
                    <div
                      className="keyfeatures-content"
                      style={{ color: "black" }}
                    >
                      {popupkeyfeatures.split("\n").map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                    </div>
                  </div>
                  {/* Download PDF button */}
                  <div className="downloadpdf" style={{ textAlign: "center" }}>
                    <a
                      href={clickPdfDownload}
                      download="product_specification.pdf"
                      style={{
                        marginTop: "30px",
                        color: "#e07811",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        textAlign: "center",
                        width: 150,
                        height: 50,
                        fontWeight: "bold",
                        textDecoration: "none",
						border: "2px solid darkred"
                      }}
                    >
                      <FaFilePdf style={{ color: "red" }} /> Download PDF
                    </a>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
             {
              video ? (
                <video autoPlay loop muted style={{minWidth:"100%", paddingLeft:"200px", paddingRight:"200px", paddingTop:"100px"}} className="text-center">
                  <source src={video} type="video/mp4" />
                </video>
              ): (
                null
              )
             }
              </div>
          </div>
          {/* Close button */}
          <div className="close">
            <button
              className="corner-button"
              onClick={TogglePopup}
              title="Close the popup"
            >
              <FaTimes style={{ color: "red", fontSize: "40px" }} />
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default ProductsCard;
