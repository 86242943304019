import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "react-slideshow-image/dist/styles.css";
import "./VioPage.css";
import { Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { Fade} from "react-awesome-reveal";
import mouseHover from "../assets/Autopilot/mousehover.gif";
import { useNavigate } from "react-router-dom";


function VioPage() {
  const fadeImages =  [ "newdrone5.jpg", "newdrone1.jpg"]; // Replace with your image paths
const navigate = useNavigate()
  const fadeProperties = {
    duration: 1000,
    transitionDuration: 1000,
    infinite: true,
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    autoplay: true,
  };
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  return (
    <div>
      <Header />

      <div className="container">
        <div className="about-header text-center">
          <h3>AJEET MINI SCOUT VIO</h3>
        </div>

        <Carousel>
          {fadeImages.map((image, index) => (
            <Carousel.Item>
              <img src={image} alt={`slide-${index}`} />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <br />

      <div
        style={{
          backgroundColor: "#0f143b",
          color: "white",
          width: "85%",
          margin: "auto",
          borderRadius: "30px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center vh-50"></div>
        <div className="tech d-flex flex-wrap justify-content-center mt-5">

      <div style={{width:"80%"}}>    <p>The AJEET Mini VIO drone integrates cutting-edge Visual Inertial Odometry (VIO) technology, enabling exact navigation and control in GPS-denied environments or areas prone to signal jamming. Designed for autonomous operations and enhanced stability, it excels in challenging terrains and confined spaces.</p>
     
         </div>
    
        </div>
        <Fade className="container-one" duration={2000} direction="left">
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '500px' }}>
              <p className="fw-bold ">Executes mapping, surveillance, and delivery missions with minimal operator intervention.</p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '500px' }}>
              <p className="fw-bold ">
              The VIO solution enables stable flight and precise navigation without GPS, which is ideal for enclosed or signal-blocked areas.
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content ">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '500px' }}>
              <p className="fw-bold ">
              Equipped with a 360° LIDAR array, the drone maps its surroundings in real-time, avoiding obstacles with precision.
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '500px' }}>
              <p className="fw-bold ">
              The LIDAR array performs Simultaneous Localization and Mapping (SLAM) to calculate its position and navigate efficiently within enclosed spaces.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
          <Col md={8} className="text-start mx-auto" style={{ maxWidth: '500px' }}>
              <p className="fw-bold ">
              An optical flow sensor ensures smooth control of tri-axis velocities, maintaining stability even in dynamic conditions.
              </p>
            </Col>
          </Row>
        
        </Fade>
      </div>

      <br />

      <div
        style={{
          backgroundColor: "orange",
          color: "black",
          width: "85%",
          margin: "auto",
          borderRadius: "30px",
        }}
      >
        <div className="tech d-flex flex-wrap justify-content-center mt-5">
          <div className="text-center">
            <p className="text-center display-1 fw-bold text-light Tech-spec-text mt-5">
              Technical Specification
            </p>
          </div>
        </div>

        <Fade className="container-one" duration={2000} direction="right">
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              UAV Weight with Battery: Maximum 1.6 Kg

              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Endurance (Ideal Wind & MSL Conditions): 25 Minutes
                </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content ">
            <Col md={8}>
              <p className="fw-bold">
              Range:1500 Meters
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Maximum Speed:25 Km/h
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Battery Charger:50W, 1-hour Charge Time
                </p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Wind Resistance:36 Km/h
                </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Auto Pilot: NavGatiTM (Proprietary) - PCB & Firmware
                </p>
            </Col>
          </Row>
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Sensors: Ground facing LIDAR (Terrain Follow), Barometer, Magnetic Compass, Accelerometer</p>
            </Col>
          </Row>
          
          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">Flight Modes; Waypoint Navigation Mode, Manual Control Mode, Hover, Position Hold</p>
            </Col>
          </Row>

          <Row className="justify-content-center fs-7 feature-content">
            <Col md={8}>
              <p className="fw-bold">
              Package: Soft Bag that houses all the sub-systems which allows the complete system to be carried and operated on the field by the crew
              </p>
            </Col>
          </Row>
       
        </Fade>
      </div>

      {/* <br />
      <br />
      <div className="text-center">
        <video autoPlay loop muted style={{ minWidth: "100%" }}>
          <source
            src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355960/xatlc73gwycu83ryj0kv.mp4"
            type="video/mp4"
          />
        </video>
      </div> */}
     
           <div className="button-box" onClick={()=> navigate("/DroneShop")}>
                <button className="buy-button">
                  <p className="buy-title text-center">Buy</p>
                  <img src={mouseHover} alt="Handshake" />
                </button>
              </div>

              <br />
              <br />
      <Footer />
    </div>
  );
}

export default VioPage;
