// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Basic Styling */
.frequentlyheader{
    color: rgb(39, 23, 68);
    text-shadow: -3px 3px 1px #fff, 2px 2px 10px #ddd, 2px -2px 0px #aaa,
    -2px -2px 10px #bbb;
}
.faqPage-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 15px;
}

.faqPage-item {
    margin: 20px 0;
    overflow: hidden;
}

.faqPage-question {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    background-color: #ffffff;
    border-radius: 40px;
    border: 2px solid rgb(67, 57, 122);
}

.faqPage-answer {
    font-family: 'Times New Roman', Times, serif;
    padding: 18px 15px;
    /* background-color: #ffffff; */
    font-size: 16px;
    transition: max-height 0.3s ease;
    text-align: justify;

}

.faqPage-icon {
    font-size:25px;
    color: orange;
}

/* Active State */
.faqPage-item.active .faqPage-answer {
    max-height: 200px;
    overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/FaqPage/FaqPage.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,sBAAsB;IACtB;uBACmB;AACvB;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,eAAe;IACf,4CAA4C;IAC5C,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,kCAAkC;AACtC;;AAEA;IACI,4CAA4C;IAC5C,kBAAkB;IAClB,+BAA+B;IAC/B,eAAe;IACf,gCAAgC;IAChC,mBAAmB;;AAEvB;;AAEA;IACI,cAAc;IACd,aAAa;AACjB;;AAEA,iBAAiB;AACjB;IACI,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":["/* Basic Styling */\r\n.frequentlyheader{\r\n    color: rgb(39, 23, 68);\r\n    text-shadow: -3px 3px 1px #fff, 2px 2px 10px #ddd, 2px -2px 0px #aaa,\r\n    -2px -2px 10px #bbb;\r\n}\r\n.faqPage-container {\r\n    max-width: 900px;\r\n    margin: 0 auto;\r\n    padding: 15px;\r\n}\r\n\r\n.faqPage-item {\r\n    margin: 20px 0;\r\n    overflow: hidden;\r\n}\r\n\r\n.faqPage-question {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    padding: 10px;\r\n    cursor: pointer;\r\n    font-family: 'Times New Roman', Times, serif;\r\n    font-weight: bold;\r\n    background-color: #ffffff;\r\n    border-radius: 40px;\r\n    border: 2px solid rgb(67, 57, 122);\r\n}\r\n\r\n.faqPage-answer {\r\n    font-family: 'Times New Roman', Times, serif;\r\n    padding: 18px 15px;\r\n    /* background-color: #ffffff; */\r\n    font-size: 16px;\r\n    transition: max-height 0.3s ease;\r\n    text-align: justify;\r\n\r\n}\r\n\r\n.faqPage-icon {\r\n    font-size:25px;\r\n    color: orange;\r\n}\r\n\r\n/* Active State */\r\n.faqPage-item.active .faqPage-answer {\r\n    max-height: 200px;\r\n    overflow: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
