import React, {useEffect} from 'react'
import ProductsCard from '../ProductsCard';
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useLocation } from "react-router-dom";


const Drones = () => {


  const productsData = [
    {
      id: 1,
      img: "images/ajeetminii.png",
      slug: "Ajeet_Mini_Trainer_(personal)",
      rating: "★★★★",
      title: "Ajeet Mini",
      smTitle:"",
      strickPrice:130000,
      price:99900,
      gst:"+ GST 18 %",
      heading:"Learn = Practice = Perfect",
      content:"DGCA’s CAT 1 [VLOS] Rotorcraft Remote Pilot Training Syllabus same for all categories Micro,Small & Medium",
      description:
        " A Great  companion to practice and perfect your drone flying skills anywhere and everywhere ",
      popupDescription:
        "A Great  companion to practice and perfect your drone flying skills anywhere and everywhere , Made in India ",
     desimg:"images/ajeetminii.png",
     popupkeyfeatures:
     " \u25CF  Auto Disarm – Calibration   \n   \u25CF  Angle Disarm \n \u25CF   Auto Disarm – Propeller \n  \u25CF   RTH on No Signal \n \u25CF  RTH on Low-battery ",
   popupspecification:
     " UAV Weight 1.172kg \nEndurance(Ideal Wind & MSL Conditions) 25 Minutes\n  Range up to 1500 Meters\n Battery 2600 mAh, 6S1P, Lithium-Ion \n GNSS Type GPS,GLONASS,QZSS, GAGAN (Up to 36 Satellites)\n Autopilot NavGati (Proprietary) - PCB & Firmware \n Sensors Ground Facing LIDAR (TerrainFollow), Barometer, Magnetic Compass, Accelerometer \n Launch & Recovery Automated Vertical Take-off & Landing\n Flight Modes Waypoint Navigation Mode, Manual Control Mode, Hover, Return to Home, Position Hold\n Packing & Storage Soft Bag that houses all the sub-systems will be provided",
    quantity: 1,
       clickPdfDownload: "/images/FAQ_Zuppa_Trainer.pdf",
       PdfDownload:"/images/Zuppa_Trainer.pdf",
      video:"https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355960/xatlc73gwycu83ryj0kv.mp4",
    },
  
   {
      id: 2,
      img: "images/ajeetminidouble.png",
      slug:"Ajeet_Mini_trainer_(For_Trainer)",
      rating: "★★★★★",
      title: "Ajeet  Mini Trainer",
      smTitle:"( For Trainers )",
      heading:"Teach = Practice = Perfect",
      content:"DGCA’s CAT 1 [VLOS] Rotorcraft Remote Pilot Training Syllabus same for all categories Micro,Small & Medium",
      description:
        " A Great  companion to practice and perfect your drone flying skills anywhere and everywhere ",
      keyfeatures:
        " \n Altitude-stablization mode \n, GPS-speed in angle mode ,\n AB operation mode, \n Automatic return mode ",
      strickPrice:160000,
      price:124900,
      gst:"+ GST 18 %",
      desimg: "images/ajeetminidouble.png",
      popupDescription:
      "A Great  companion to practice and perfect your drone flying skills anywhere and everywhere , Made in India “ Personal Trainer Drone “ ",
      popupkeyfeatures:
      " \u25CF  Auto Disarm – Calibration   \n   \u25CF  Angle Disarm \n \u25CF   Auto Disarm – Propeller \n  \u25CF   RTH on No Signal \n \u25CF  RTH on Low-battery ",
    popupspecification:
      " UAV Weight with Battery Maximum 1.2kgs \nEndurance(Ideal Wind & MSL Conditions) 25 Minutes\n  Range up to 1500 Meters\n Battery 4200 mAh, 6S1P, Lithium-Ion \n GNSS Type GPS,GLONASS,QZSS, GAGAN (Up to 36 Satellites)\n Autopilot NavGati (Proprietary) - PCB & Firmware \n Sensors Ground Facing LIDAR (TerrainFollow), Barometer, Magnetic Compass, Accelerometer \n Launch & Recovery Automated Vertical Take-off & Landing\n Flight Modes Waypoint Navigation Mode, Manual Control Mode, Hover, Return to Home, Position Hold\n Packing & Storage Soft Bag that houses all the sub-systems will be provided",
       quantity: 1,
     clickPdfDownload: "/images/FAQ_Zuppa_DGCA.pdf",
     video:"https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355960/xatlc73gwycu83ryj0kv.mp4",
  
  },
  
  
    {
      id: 3,
      img: "images/scoutt.png",
      slug:"Ajeet_Scout",
      rating: "★★★★",
      title: "Ajeet Scout",
      strickPrice:249000,
      price: 199900,
      gst:"+ GST 18 %",
      heading:"  Perfect Camera Drone ",
      content:"Only Made In India surveillance drone in a backpack",
     
      description:
        " Easy to deploy and operate by a single person from any unprepared surface ",
      keyfeatures:
        " \n Altitude-stablization mode \n, GPS-speed in angle mode ,\n AB operation mode, \n Automatic return mode ",
      popupDescription:
        " Easy to deploy and operate by a single person from any unprepared surface,Only Made In India surveillance drone in a backpack",
        desimg:  "images/scoutt.png",
        popupkeyfeatures:
        " \u25CF  Auto Disarm – Calibration   \n   \u25CF  Angle Disarm \n \u25CF   Auto Disarm – Propeller \n  \u25CF   RTH on No Signal \n \u25CF  RTH on Low-battery ",
      popupspecification:
        "  UAV Weight with Battery Maximum 1.44kgs \nEndurance(Ideal Wind & MSL Conditions) 25 Minutes\n  Range up to 1500 Meters\n Battery 4200 mAh, 6S1P, Lithium-Ion \n GNSS Type GPS,GLONASS,QZSS, GAGAN (Up to 36 Satellites)\n Autopilot NavGati (Proprietary) - PCB & Firmware \n Sensors Ground Facing LIDAR (TerrainFollow), Barometer, Magnetic Compass, Accelerometer \n Launch & Recovery Automated Vertical Take-off & Landing\n Flight Modes Waypoint Navigation Mode, Manual Control Mode, Hover, Return to Home, Position Hold\n Packing & Storage Soft Bag that houses all the sub-systems will be provided",
      quantity: 1,
      clickPdfDownload: "/images/FAQ_Zuppa_Scout.pdf",
      video:"https://res.cloudinary.com/dmv2tjzo7/video/upload/v1725355947/yyqnzl5uylsnf5230bdk.mp4",
    },

  ];

  const location = useLocation();

  useEffect(() => {
    // Extract slug from query parameters
    const queryParams = new URLSearchParams(location.search);
    const slug = queryParams.get("slug");

    if (slug) {
      // Find the element with the matching slug and scroll into view
      const element = document.getElementById(slug);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.search]);

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);
  
  return (
      <>
      <Header />
      <div className="container mt-8">
        <h1 className="about-header text-center mb-0">DRONES</h1>

        <div className="row">
     
          <section id="home">
                 <div className="home_content" style={{marginTop:"-60px"}}>
                 {productsData.map((item) => (
                <ProductsCard key={item.id}  data-slug={item.slug}  {...item} />
              ))}
                    </div>
             
            </section>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Drones





