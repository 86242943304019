import React, { useEffect, useState } from "react";
import "./News.css";
import Header from "../components/header";
import Footer from "../components/footer";
import { Fade } from "react-awesome-reveal";


const News = () => {
  // JSON data
  const blogs = [
    {
      title: "Desi drone makers hums Chinese piracy",
      author: "The Economic Times",
      authorImage: "https://shop.zuppa.io/Ajeetscoutcrop.png",
      date: "27",
      month: "NOVEMBER",
      thumbnail:
        "Ajeetscoutcrop.png",
      content:
        "Chennai-based Zuppa Geo Navigation Technology has written to Directorate General of Foreign Trade (DGFT), seeking a ban on imports from Chinese companies that, it alleges, have infringed on its patent. In a representation to DGFT, the Indian company has said it was awarded a patent in April this year for a real-time computing architecture called 'system of disseminated parallel control computing in real time",
        link: "https://economictimes.indiatimes.com/news/defence/desi-drone-maker-hums-chinese-piracy/articleshow/115742605.cms?from=mdr",
        Fade:"left"
      },
    {
      title: "Indian Drone Company Alleges Copyright Infringement by Chinese Entities, Seeks Government Intervention",
      author: "Indian Defence Research Wing",
      authorImage: "https://randomuser.me/api/portraits/women/44.jpg",
      date: "29",
      month: "NOVEMBER",
      thumbnail:
        "autopilots-slide2crop.png",
      content:
        "In a significant development highlighting the challenges of intellectural propery (IP) rights protection, Chennai-based Zuppa Geo Navigation Technology has accused Chinese firms of infringing upon its autopilot technology",
        link: "https://idrw.org/indian-drone-company-alleges-copyright-infringement-by-chinese-entities-seeks-government-intervention/",
         Fade:"right"
      },
    {
      title: " चीन की हरकत से आगबबूला हुआ भारत, लगाएगा ड्रैगन की इस कंपनी पर बैन, जानें वजह",
      author: "ABP Live",
      authorImage: "https://randomuser.me/api/portraits/men/80.jpg",
      date: "01",
      month: "DECEMBER",
      thumbnail:
        "drone-slide1crop.png",
      content:"Indian drone company: एक भारतीय ड्रोन कंपनी ने चीनी संस्थाओं द्वारा अपनी ऑटोपायलट तकनीक के कॉपीराइट उल्लंघन का आरोप लगाया है. इसके बाद भारत सरकार से अपने अधिकारों की रक्षा के लिए आयात पर रोक लगाने को कहा है. चेन्नई स्थित ज़ुप्पा जियो नेविगेशन टेक्नोलॉजी ने विदेश व्यापार महानिदेशालय (DGFT) को पत्र लिखकर उन चीनी कंपनियों से Import पर पर बैन लगाने की मांग की है, जिन पर उसका आरोप है कि उन्होंने उसके पेटेंट का उल्लंघन किया है.",
        link: "https://www.abplive.com/news/world/indian-drone-company-zuppa-geo-navigation-technology-claim-china-copy-their-autopilot-technique-2833916",
     
        Fade:"left" },


      {
        title: " Zuppa claims their drones entirely manufactured in India cannot be hijacked",
        author: "The New Indian Express",
        authorImage: "https://randomuser.me/api/portraits/men/80.jpg",
        date: "23",
        month: "DECEMBER",
        thumbnail:
          "autopilots-slide3crop.png",
        content:" Indian army drone that was flying close to the border in the north was reportedly hijacked by Pakistani authorities. The reason, Zuppa claims, was because the drone was made through Chinese technology and hence was vulnerable.",
          link: "https://www.newindianexpress.com/nation/2024/Dec/23/zuppa-claims-their-drones-entirely-manufactured-in-india-cannot-be-hijacked",
            Fade:"right"
        },
        {
          title: " No Chinese parts, no worries: Indian drone maker Zuppa offers hijack-proof systems for India's defence",
          author: "The Week",
          authorImage: "https://randomuser.me/api/portraits/men/80.jpg",
          date: "23",
          month: "DECEMBER",
          thumbnail:
            "drone-slide3crop.png",
          content:"Indian drone maker Zuppa claims its drones are hijack-proof due to no Chinese components, addressing security concerns after reports of Pakistan exploiting Chinese tech in Indian drones",
            link: "https://www.theweek.in/news/defence/2024/12/23/zuppa-claims-its-indian-made-drones-are-hijack-proof-due-to-no-chinese-components-addressing-security-concerns-after-reports-of-pakistan-exploiting-chinese-tech-in-indian-drones.html",
          
            Fade:"left"},


          {
            title: " After Hijacking Of Indian Army Drone Near Pakistan Border, Focus On Make-In-India Without Chinese Parts—Zuppa Offers Solution",
            author: "SWARAJYA",
            authorImage: "https://randomuser.me/api/portraits/men/80.jpg",
            date: "23",
            month: "DECEMBER",
            thumbnail:
              "Trainer_dronecrop.jpeg",
            content:"zuppa geo navigation asserts that its drones, built entirely with indigenous parts, are impervious to hijacking, a risk commonly associated with drones assembled using Chinese technology",
              link: "https://swarajyamag.com/news-brief/after-hijacking-of-indian-army-drone-near-pakistan-border-focus-on-make-in-india-without-chinese-partszuppa-offers-solution",
                Fade:"right"
            },
            



            {
              title: " Indian Army’s strategic move to secure drones with homegrown cyber defence technology: ZUPPA Suraksha",
              author: "FINANCIAL EXPRESS",
              authorImage: "https://randomuser.me/api/portraits/men/80.jpg",
              date: "25",
              month: "DECEMBER",
              thumbnail:
                "https://res.cloudinary.com/dmv2tjzo7/image/upload/v1735556352/aue4hguurmsygwgrxz0s.avif",
              content:"The Suraksha EMD is engineered to monitor and secure communication channels between drones and their Ground Control Stations (GCS). The Indian Army has increasingly turned to drones for surveillance, reconnaissance, and even combat roles, enhancing operational efficiency and reducing the risks to human soldiers",
                link: "https://www.financialexpress.com/business/defence/indian-armys-strategic-move-to-secure-drones-with-homegrown-cyber-defence-technology-zuppa-suraksha/3700027/",
                  Fade:"left"
              },





              {
                title: " Building Secure Bharatiya Cyber Physical Drone Stack  ( SEP 2024) Key to Atmanirbharta and Global Drone Hub Vision 2030",
                author: "IDSA",
                authorImage: "https://randomuser.me/api/portraits/men/80.jpg",
                date: "26",
                month: "SEPTEMBER",
                thumbnail:
                  "defensebook.jpg",
                content:"Unmanned systems like drones are being deployed across battlefields the world over in numbers like never seen before, for a range of missions like surveillance, logistics, terrain-mapping to kinetic attack in the form of Loitering Munitions (LMs)",
                  link: "https://www.idsa.in/wp-content/uploads/2024/11/16-jds-18-3-2024-Sai-Pattabiram.pdf ",
                    Fade:"right"
                },



              {
                title: " Indian Army Strengthens Drone Security with Indigenous Cyber Defence Technology",
                author: "CXO Digital Pulse",
                authorImage: "https://randomuser.me/api/portraits/men/80.jpg",
                date: "26",
                month: "DECEMBER",
                thumbnail:
                  "Trainer_dronecrop.jpeg",
                content:"ZUPPA’s Suraksha technology represents a major leap forward in safeguarding India’s drone capabilities. By focusing on indigenous innovation, the Indian Army not only enhances its operational readiness but also contributes to the nation’s strategic goal of technological self-sufficiency",
                  link: "https://www.cxodigitalpulse.com/indian-army-strengthens-drone-security-with-indigenous-cyber-defence-technology/",
                    Fade:"left"
                },
                {
                  title: " After Hijacking of Indian Army Drone Near Pakistan Border—Zuppa Offers Unhijackable Solution Without Chinese Parts",
                  author: " Indian Defence News",
                  authorImage: "https://randomuser.me/api/portraits/men/80.jpg",
                  date: "25",
                  month: "DECEMBER",
                  thumbnail:
                    "drone-slide3crop.png",
                  content:"Zuppa claims that its drones are hijack-proof because they are entirely manufactured in India without any Chinese components. This approach addresses security concerns raised after reports indicated that the Indian Army's drones had been compromised due to the use of Chinese technology.",
                    link: "https://www.indiandefensenews.in/2024/12/after-hijacking-of-indian-army-drone.html",
                     Fade:"right"
                  },
  ];

  
  useEffect(() => {
    const body = document.querySelector('#root'); 
      body.scrollIntoView({
          behavior: 'smooth'
      }, 500)
    
   
    }, []);

  return (
<>

<Header />
    <div className="newsevent-header text-center">
    <h2>Our Company in News</h2>

  </div>

   
  <div className="blog-card-container" style={{ marginTop: "130px" }}>
      {blogs.map((blog, index) => (
        <div className="fond" key={index}>
 <Fade direction={`${blog.Fade}` } duration={1800}>
          <div className="news-cards">
            <div className="news-thumbnails">
          

            <img
                className="news-left-image"
                src={blog.thumbnail}
                alt="Thumbnail"
                style={
                  index === 1|| index === 2 || index === 3|| index === 4 || index === 9
                    ? {
                       
                     marginTop:"-40px"
                      }
                    : {}
                }
              />
                 </div>



            <div className="right">
              <h1 className="news-heading-blog">{blog.title}</h1>
              {/* <div className="author">
                <img src={blog.authorImage} alt="Author" />
                <h2 className="news-subhead-blog">{blog.author}</h2>
              </div> */}
              <div className="separator"></div>
              <p className="news-content">{blog.content}</p>
            </div>
            <div className="news-footer">
              <div className="date-container">
                <h5 className="date">{blog.date}</h5>
                <h6 className="month">{blog.month}</h6>
              </div>
              <div className="learn-more-container">
              <button
                  className="learn-more"
                  onClick={() => window.open(blog.link, "_blank")}
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
          </Fade>
        </div>
      ))}
    </div>

    <Footer />
    </>
  );
};

export default News;
