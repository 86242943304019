import React from 'react';
import Header from "../components/header";
import Footer from "../components/footer";
import downloadimage from "../assets/images/259.jpg";
import { FaFileDownload, FaFilePdf,  FaGooglePlay } from "react-icons/fa";

function Download() {
  const handleDownloadClick = () => {
    console.log('APK download initiated');
  };

  return (
    <div>
      <Header />
      <div className="container">
        <div className="about-header text-center">
          <h3>Download</h3>
        </div>
      </div>
      <br></br>
      <br></br>
      <div
        style={{
          backgroundColor: "#f9c38a",
          color: "white",
          width: "90%", // Increased width to better fit the two columns
          margin: "auto",
          padding: "30px",
        }}
      >
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <div
              style={{
                backgroundColor: "#ffe4b5",
                padding: "50px",
                borderRadius: "8px",
                width: "100%",
                color: "white",
              }}
            >
              <div>
                <h2 style={{ color: "#0f143b", marginRight:'10'}}>Zuppa Disha GCS 3.4.9 GEN4</h2>
                <a
                href={`${process.env.PUBLIC_URL}/Zuppa_DISHA_GCS_349_ALPHA.exe`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#0000ff", textDecoration: "none", display: "flex", alignItems: "center" }}
                >
                  <FaFileDownload style={{ width: '30px', height: '30px', marginRight: '10px', color: 'green' }} />
                  Click here to Download windows Gcs
                </a>
              </div>

              <div className="mt-4">
                <h2 style={{ color: "#0f143b" }}>Open Gcs App</h2>
                <a
                  href={`${process.env.PUBLIC_URL}/Zuppa_OpenGCS_4.5.apk`}
                  download="ZuppaGcsApp.apk"
                  style={{ color: "#0000ff", textDecoration: "none", display: "flex", alignItems: "center" }}
                >
                  <FaFileDownload style={{ width: '30px', height: '30px', marginRight: '10px', color: 'green' }} />
                  Click here to Download Open Gcs App
                </a>
              </div>

              <div className="mt-4">
              <h2 style={{ color: "#0f143b" }}>Zuppa Gcs App </h2>
                <a
                  href="https://play.google.com/store/apps/details?id=com.zuppagcs&pcampaignid=web_share"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#0000ff", textDecoration: "none", display: "flex", alignItems: "center" }}
                >
                  <FaGooglePlay style={{ width: '30px', height: '30px', marginRight: '10px',color: 'white' }} />
                  Click here to Download Mobile Gcs App
                </a>
              </div>

              <div className="mt-4">
                <h2 style={{ color: "#0f143b" }}>Ajeet Mini/Scout Instruction Manual</h2>
                <a
                  href={`${process.env.PUBLIC_URL}/SCOUT INSTRUCTION MANUAL.pdf`}
                  download="Ajeet mini/scout instruction manual.pdf"
                  className="text-white text-decoration-none d-flex align-items-center"
                >
                  <FaFilePdf style={{ color: "red", marginRight: "10px", width: '30px', height: '30px' }} />
                  <span style={{color:" #0000ff"}}>Click here to Download Ajeet mini/scout instruction manual</span>
                </a>
              </div>
			  
			  <div className="mt-4">
                <h2 style={{ color: "#0f143b" }}>Autopilot</h2>
                <a
                  href={`${process.env.PUBLIC_URL}/images/Autopilots.pdf`}
                  download="Zuppa_Autopilots.pdf"
                  className="text-white text-decoration-none d-flex align-items-center"
                >
                  <FaFilePdf style={{ color: "red", marginRight: "10px", width: '30px', height: '30px' }} />
                  <span style={{color:" #0000ff"}}>Click here to Download Autopilot</span>
                </a>
              </div>
			   <div className="mt-4">
                <h2 style={{ color: "#0f143b" }}>DGCA Type Certificate</h2>
                <a
                  href={`${process.env.PUBLIC_URL}/TYPE_CERTIFICATE.pdf`}
                  download="TYPE_CERTIFICATE.pdf"
                  className="text-white text-decoration-none d-flex align-items-center"
                >
                  <FaFilePdf style={{ color: "red", marginRight: "10px", width: '30px', height: '30px' }} />
                  <span style={{color:" #0000ff"}}>Click here to Download</span>
                </a>
              </div>
			  
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img
              src={downloadimage} // Replace with your actual image path
              alt="Description of the image"
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Download;
