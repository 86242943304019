
import React from 'react';
import "./Technology.css";
import Header from "../components/header";
import Footer from '../components/footer';
import {Fade} from "react-awesome-reveal"
import { colors } from '@mui/material';

const Technology = () => {


// JSON data
const blogs = [
  
  {
    title: "HUMAN INTELLIGENCE : PARALLEL PROCESSING",
    thumbnail:"https://media.licdn.com/dms/image/D4D12AQF_w7GFjpbt4g/article-cover_image-shrink_600_2000/0/1686981310860?e=2147483647&v=beta&t=yc_cWzw5xYxM-1PonUMctSl3nWgET57KK0gaLtEZlbY",
    content:
    "When we drive a car, we don't focus only on the car and driving it; we also look for obstacles on the road, movement of other vehicles, avoid crashing into pedestrians or animals crossing the road, listen to music, carry on a conversation with our passengers, and continue navigating our car to reach the destination we started off towards .We do all this simultaneously while navigating through a series of turns and bends and arrive at our planned destination .Parallel processing is our ability to deal with multiple stimuli simultaneously to control vehicles .Our ability to process all these different kinds of stimuli at once through our reflex and cognitive nerve systems enables our brain to direct our hands and legs to act in unison while performing the act of driving .",
  backgroundColors:"#06022e",
  Fade:"left"    
  },

  {
    title: "ZUPPA’S A. I: DPCC",
    thumbnail:"https://readitquik.com/wp-content/uploads/2022/02/ai-1.gif",
     content:"ZUPPA’s “SYSTEM OF DISSEMINATED PARALLEL CONTROL COMPUTING IN REAL TIME (DPCC)” is a Bio Mimicry of the “Human Intelligence system of parallel processing .DPCC is a control computing architecture that performs in a way similar to Human intelligence to control vehicles like Drones in the air,UGV’s on the ground or USV’s in water.DPCC processes data from sensors like IMU’s , Accelerometer , Gyro, magnetometer , barometer , GPS to control and navigate the vehicle and make them unmanned or driverless .ZUPPA’s NavGati Motherboard for unmanned systems and Drishya for Vision guidance & navigation are all set to lead 5th Generation technologies in the emerging industries of Drones, Defence , ADAS , IoT and connected mobility.",
    backgroundColors:"#d48b02",
    Fade:"right"    
    },

    {
      title: "ZUPPA AI - THE TECHNOLOGY",
      thumbnail:"https://res.cloudinary.com/dmv2tjzo7/image/upload/v1735558056/ht2r8vqgi2bysblijsew.png",
       content:"UNMANNED SYSTEMSNavGati Autopilot: is a motherboard that uses data from sensors like IMU, Accelerometer, Gyro, magnetometer, barometer GPS to control a vehicle along X, Y & Z axis electronically in exactly the same way a human would...VISION GUIDANCE SYSTEMS Drishaya Vision: is a motherboard that uses live camera feed much like we Humans use our eyes for driving or piloting a vehicle in real time. DPCC also processes video feeds from Multiple cameras to create 360 Deg Vision Based guidance and navigation systems . IOT & CONNECTED MOBILITY EDGE Data Gateway: is a motherboard that acquires and transmits data to cloud servers using wireless technologies.",
       backgroundColors:"#06022e",
       Fade:"left"     
      },

];

  return (
<div>
        <Header />
       {/* Parallax Header */}
       <div className="parallax-header">
        <img className='awardHeaderImageTechno' src='https://res.cloudinary.com/dmv2tjzo7/image/upload/v1735539395/rdl8pd6aqdfox53oa28f.png'/>
       </div>
 
<div className="blog-card-container-techno" style={{ marginTop: "10px" }}>
    {blogs.map((blog, index) => (
      <div className="fond-techno" key={index}>
         <Fade direction={`${blog.Fade}` } duration={1800}>
        <div className="news-cards-techno" style={{backgroundColor:`${blog.backgroundColors}`}} >
          <div className="news-thumbnails-techno">
            <img
              className="news-left-image-techno"
              src={blog.thumbnail}
              alt="Thumbnail"
            />
          </div>
          <div className="right-techno">
            <h1 className="news-heading-blog-techno">{blog.title}</h1>
          
            <div className="separator-techno"></div>
            <p className="news-content-techno">{blog.content}</p>
          </div>
          {/* <div className="news-footer-techno">
            <div className="date-container-techno">
              <h6 className="date-techno">{blog.date}</h6>
              <h6 className="month-techno">{blog.month}</h6>
            </div>
            <div className="learn-more-container-techno">
            <button
                className="learn-more-techno"
                onClick={() => window.open(blog.link, "_blank")}
              >
                View More
              </button>
            </div>
          </div> */}
        </div>
        </Fade>

      </div>
    ))}
  </div>



<Footer />

</div>
  );
}

export default Technology;
